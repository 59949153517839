import { ChangeLogDto, PagingOptions } from "@libs/api/dtos/index.ts";
import { RefDataDto } from "@libs/api/ref-data/dto.ts";

import { CorrespondenceType } from "../clinical/ClinicalGateway.dtos.ts";
import { AddressDto } from "../practice/PracticeGateway.dtos.ts";

export interface HealthCertificateDto extends AddHealthCertificateDto {
  id: number;
  certificateExpiryDate: string;
}

export type AddHealthCertificateDto = {
  certificateName: string;
  certificateFile: string;
  certificatePassword: string;
};

export type ClaimProviderDto = {
  providerFirstName?: string | undefined;
  providerMiddleName?: string | undefined;
  providerLastName?: string | undefined;
  providerTypeCode?: string | undefined;
  hpiCpn?: string | undefined;
};

export interface AddClaimDto
  extends Omit<ClaimDto, "eTag" | "changeLog" | "id"> {
  id?: string;
}

export interface ClaimDiagnosisDto {
  diagnosisCodeType?: string; //readonly property
  diagnosisSide?: string;
  requestApproved?: boolean;
  terminology?: {
    code: string;
    text: string;
    diagnosisKey: string;
    aCCAcceptable?: boolean;
    aCC32Acceptable?: boolean;
    readCode?: string;
    version?: string;
    codeSystem?: string;
  };
}

export interface ReferralDiagnosisDto extends ClaimDiagnosisDto {
  diagnosisStatus?: string;
}

export interface ServiceItemsDto {
  serviceItemCode?: string;
  spent?: string;
}

export interface ClaimAddressDto
  extends Omit<AddressDto, "type" | "state" | "country"> {
  type?: string;
  country?: string;
}

export interface AppointmentVisitDto {
  previousInitialConsultVisits?: number;
  previousFollowUpVisits?: number;
  contractType?: string;
  providerType?: string;
  totalRemaining?: number;
  extensions?: number;
  allocated?: number;
  booked?: number;
  billed?: number;
}

export interface ClaimAdjustmentDto {
  id: string;
  eTag: string;
  claimId: string;
  accRelatedSurgery?: boolean;
  causeOfCurrentCondition?: string;
  causeOfNotResolvedCondition?: string;
  changeLog?: ChangeLogDto;
  claimNumber?: string;
  claimAdjustmentNumber?: number;
  providerContractType?: string;
  currentConditionOrDiagnosis?: string;
  expectedTreatmentCompletionDate?: string;
  hpiCpn?: string;
  hpiOrganisationNumber?: string;
  isVoluntarySubmission?: boolean;
  managementPlan?: string;
  mostRecentSurgeryDate?: string;
  originalAccidentEvent?: string;
  patientAddress?: ClaimAddressDto;
  patientDateOfBirth?: string;
  patientDeclarationConfirmed?: boolean;
  patientFirstName?: string;
  patientLastName?: string;
  proposedTreatment?: string;
  providerContactNumber?: string;
  providerDeclarationConfirmed?: boolean;
  providerFirstName?: string;
  providerId?: string;
  providerLastName?: string;
  purchaseOrderNumber?: string;
  responseDate?: string;
  responseNotes?: string;
  signAndSymptom?: string;
  statusCode?: string;
  treatmentStartDate?: string;
  claimAdjustmentDocuments?: ClaimAdjustmentDocumentDto[];
  claimAdjustmentRequests: ClaimAdjustmentRequestArray;
  errorMessages?: ErrorMessageDto[];
  clinicalDocumentId?: string;
  verballyApproved?: boolean;
}

export type ClaimAdjustmentRequestArray = Array<
  | DiagnosisAdditionRequestDto
  | DiagnosisChangeRequestDto
  | SideChangeRequestDto
  | TreatmentRequestDto
>;

export interface PatchClaimAdjustmentDto
  extends Patch<Omit<ClaimAdjustmentDto, "changeLog" | "eTag">> {
  id: string;
  eTag: string;
}

export interface AddClaimAdjustmentDocumentDto
  extends Omit<
    ClaimAdjustmentDocumentDto,
    "claimAdjustmentDocumentId" | "claimAdjustmentId"
  > {
  claimAdjustmentDocumentId?: string;
}

export interface ClaimAdjustmentDocumentDto {
  claimAdjustmentDocumentId: string;
  claimAdjustmentId: string;
  documentId?: string;
  stagingId?: string;
  status?: ClaimAdjustmentDocumentStatuses | undefined;
  extension?: string | undefined;
  fileName?: string;
  contentType?: string;
  assessmentType?: string;
  bodySite?: string;
  errorMessages?: ErrorMessageDto[];
  isDocumentAcc32Form?: boolean;
}

export interface AddClaimAdjustmentDto
  extends Omit<ClaimAdjustmentDto, "eTag" | "changeLog" | "id"> {
  id?: string;
}

export interface GetClaimAdjustmentArgsDto {
  claimId?: string;
}

export interface GetClaimReviewArgsDto {
  claimId?: string;
}

export interface GetPurchaseOrderArgsDto {
  claimAdjustmentId?: string;
  claimId?: string;
}

export interface ClaimDiagnosisChangeDto {
  oldDiagnosis: ClaimDiagnosisDto;
  newDiagnosis: ClaimDiagnosisDto;
}

export interface ClaimSideChangeDto {
  oldDiagnosis: ClaimDiagnosisDto;
  newSide: string;
}

export enum ClaimAdjustmentStatus {
  Accepted = "ACCEPTED",
  Error = "ERROR",
  DocumentError = "DOCUMENT ERROR",
  Declined = "DECLINED",
  Incomplete = "INCOMPLETE",
  Partial = "PARTIALLY ACCEPTED",
  Pending = "PENDING",
  Ready = "READY",
  Queued = "QUEUED",
  SendEmail = "SEND EMAIL"
}

export const responseStatusesArray = [
  "ACCEPTED",
  "DECLINED",
  "PARTIALLY ACCEPTED"
];

export enum ClaimAdjustmentDocumentStatuses {
  Staged = "STAGED",
  PdfRendered = "PDF RENDERED",
  SavedToCorrespondence = "SAVED TO CORRESPONDENCE",
  AttachedFromCorrespondence = "ATTACHED FROM CORRESPONDENCE",
  AwaitingCorrespondence = "AWAITING CORRESPONDENCE",
  SendToAcc = "SEND TO ACC",
  Lodged = "LODGED",
  Error = "ERROR"
}

export enum ClaimAdjustmentRequestType {
  TreatmentRequest = "TREATMENT REQUEST",
  DiagnosisAddition = "DIAGNOSIS ADDITION",
  DiagnosisChange = "DIAGNOSIS CHANGE",
  SideChange = "SIDE CHANGE"
}

export interface ClaimAdjustmentRequests {
  claimAdjustmentRequestTypeCode: ClaimAdjustmentRequestType;
  requestApproved?: boolean;
}

export interface DiagnosisAdditionRequestDto extends ClaimAdjustmentRequests {
  diagnosisAdditions: ClaimDiagnosisDto[];
}

export interface DiagnosisChangeRequestDto extends ClaimAdjustmentRequests {
  diagnosisChanges: ClaimDiagnosisChangeDto[];
}

export interface SideChangeRequestDto extends ClaimAdjustmentRequests {
  sideChanges: ClaimSideChangeDto[];
}

export interface TreatmentRequestDto extends ClaimAdjustmentRequests {
  handSplintingCost?: number;
  treatmentStartDate?: string;
  treatmentsApproved?: number;
  treatmentsRequested?: number;
  initialAssessmentsNeeded?: number;
  followUpTreatmentsNeeded?: number;
  handSplintingCostApproved?: number;
  initialAssessmentsApproved?: boolean;
  abscessHematomaTreatmentsNeeded?: number;
  nailSimpleRemovalTreatmentsNeeded?: number;
  firstAdditionalTreatmentRequested?: boolean;
  nailRemovalResectionTreatmentsNeeded?: number;
  abscessHematomaTreatmentsNeededApproved?: number;
  nailSimpleRemovalTreatmentsNeededApproved?: number;
  nailRemovalResectionTreatmentsNeededApproved?: number;
}

export interface DiagnosisChangeRequestDto extends ClaimAdjustmentRequests {
  diagnosisChanges: ClaimDiagnosisChangeDto[];
}

export interface SideChangeRequestDto extends ClaimAdjustmentRequests {
  sideChanges: ClaimSideChangeDto[];
}

export interface ClaimDiagnosisChangeDto {
  oldDiagnosis: ClaimDiagnosisDto;
  newDiagnosis: ClaimDiagnosisDto;
  requestApproved?: boolean;
}

export interface ClaimSideChangeDto {
  oldDiagnosis: ClaimDiagnosisDto;
  newSide: string;
  requestApproved?: boolean;
}

export type ClaimAdjustmentResponseRequest =
  | DiagnosisAdditionRequestDto
  | DiagnosisChangeRequestDto
  | SideChangeRequestDto
  | TreatmentRequestDto;

export interface ClaimDto {
  id: string;
  eTag: string;
  patientId?: string;
  providerId?: string;
  claimNumber?: string;
  statusCode?: string;
  previousClaimStatusCode?: string;
  patientFirstName?: string;
  patientMiddleName?: string;
  patientLastName?: string;
  providerFirstName?: string;
  providerMiddleName?: string;
  providerLastName?: string;
  occupation?: string;
  inPaidEmployment?: boolean;
  employmentStatus?: string;
  otherEmployment?: string;
  workType?: string;
  employerName?: string;
  addressType?: string;
  accidentDate?: string;
  accidentLocationCode?: string;
  accidentSceneCode?: string;
  causeOfAccident?: string;
  involvesVehicle?: boolean;
  medicalTreatmentInjury?: boolean;
  workInjury?: boolean;
  sportingInjury?: boolean;
  sport?: string;
  gradualProcessInjury?: boolean;
  injuryComments?: string;
  assistanceRequired?: boolean;
  accContactProvider?: boolean;
  transmissionStatus?: number;
  hpiCpn?: string;
  hpiOrganisationNumber?: string;
  hpiFacilityNumber?: string;
  practiceName?: string;
  providerTypeCode?: string;
  patientMobileNumber?: string;
  patientWorkPhoneNumber?: string;
  patientHomePhoneNumber?: string;
  patientEmail?: string;
  patientNhiNumber?: string;
  patientDateOfBirth?: string;
  patientSex?: string;
  patientEthnicityCode?: string;
  providerDeclaration?: string;
  providerDeclared?: boolean;
  patientAddress?: ClaimAddressDto;
  employerAddress?: ClaimAddressDto;
  providerAddress?: ClaimAddressDto;
  claimDiagnosis?: ClaimDiagnosisDto[];
  referralDiagnosis?: ReferralDiagnosisDto[];
  currentDiagnoses?: ClaimDiagnosisDto[];
  claimWorkCapacityJson?: string;
  claimReferralJson?: string;
  patientDeclared?: boolean;
  patientClaimAuthorised?: boolean;
  patientInfoAuthorised?: boolean;
  patientAccContactAuthorised?: boolean;
  patientDeclaration?: string;
  telehealthConsultation?: boolean;
  telehealthDeclared?: boolean;
  telehealthAuthorisedHealthProfessional?: boolean;
  telehealthAuthorisedRecords?: boolean;
  errorMessages?: ErrorMessageDto[];
  discharged?: string; // date string
  private?: boolean;
  referralOut?: boolean;
  referralIn?: boolean;
  referralDetails?: ReferralDetailDto[];
  changeLog?: ChangeLogDto;
  insurerContactId?: string;
  referralDate?: string; // date string
  referralProvider?: string;
  allocated?: number;
  extensions?: number;
  previousInitialConsultVisits?: string;
  previousFollowUpVisits?: string;
  booked?: string;
  billed?: number;
  totalRemainingAppointments?: string;
  serviceItems?: ServiceItemsDto[];
  employerId?: string;
  appointmentVisits?: AppointmentVisitDto[];
  claimAdjustments?: ClaimAdjustmentDto[];
  initialConsultDate?: string;
  insurerName?: string;
  isImported?: boolean;
  isOnHold?: boolean;
}
export interface PatchClaimDto extends Patch<Omit<ClaimDto, "changeLog">> {
  id: string;
}
export interface ErrorMessageDto {
  message: string;
  field?: string;
  details?: string;
  code?: string;
  lastRequestedDateTime?: string;
  nextRequestDateTime?: string;
  attemptNumber?: number;
  maxAttempts?: number;
}

export interface DocumentErrorMessageDto {
  message: string;
  field?: string;
  details?: string;
}

export interface ReferralDetailDto {
  providerTypeCode: string;
  referralReason: string;
}

export type GetClaimsDto = {
  claimNumber?: string;
  patients?: string[];
  providers?: string[];
  claimStatusCode?: string[];
  discharged?: boolean;
  dateFrom?: string;
  dateTo?: string;
  insurers?: string[];
  claimIds?: string[];
  claimsActionRequired?: boolean;
  excludePrivate?: boolean;
};
export type GetClaimsArgsDto = GetClaimsDto & PagingOptions;

export interface ClaimAppointmentDto {
  id: string;
  claimId: string;
  calendarEventId: string;
  eTag: string;
  contractType?: string;
  providerType?: string;
}

export interface AddClaimAppointmentDto
  extends Omit<ClaimAppointmentDto, "id" | "eTag"> {}

export interface GetClaimAppointmentArgsDto {
  id?: string;
  claimIds?: string[];
  calendarEventId?: string;
}

export interface ClaimEncounterDto {
  id: string;
  claimId: string;
  encounterId: string;
  eTag: string;
}

export interface CreateClaimEncounterDto
  extends Omit<ClaimEncounterDto, "id" | "eTag"> {}

export interface ClaimsFilter extends GetClaimsArgsDto {}

export interface DeleteClaimEpisodeOfCareArgs {
  claimId?: string;
  episodeOfCareId?: string;
}
export interface ClaimEpisodeOfCareDto {
  id: string;
  claimId: string;
  episodeOfCareId: string;
  isActive: boolean;
  eTag: string;
}

export interface DeleteCheckDto {
  claimDeleteRequestResponseCode: string;
  claimDeleteCheckResponseText: string;
}

export interface ClaimReviewDto {
  id: string;
  claimId: string;
  patientId: string;
  clinicalDirectorId: string;
  providers?: string[];
  claimNumber?: string;
  totalVisits?: number;
  dateOfInjury?: string;
  dateOfSurgery?: string;
  currentDiagnoses?: ClaimDiagnosisDto[];
  initialDiagnoses?: ClaimDiagnosisDto[];
  eTag: string;
  changeLog?: ChangeLogDto;
  mechanismOfInjury?: string;
  reviewInjuryAndDiagnosis?: string;
  statementOnCausation?: string;
  statementOnCausationDetails?: string;
  reviewOfCurrentTreatment?: string;
  documentationOfAnyRecommendationsAndActions?: string;
  liaisonWithProviderUndertaken?: string;
  liaisonWithProviderUndertakenDetails?: string;
  clinicalDocumentId?: string;
  statusCode?: string;
  encounterId?: string;
  documentStatus?: string;
}

export interface AddClaimReviewDto
  extends Omit<ClaimReviewDto, "eTag" | "changeLog" | "id"> {
  id?: string;
}

export interface PatchClaimReviewDto extends PatchWithId<ClaimReviewDto> {}

export interface ClaimFormInstanceDTO {
  claimId: string;
  formInstanceId: string;
  formTemplateTypeCode: string;
}

export interface ClaimEpisodeOfCareArgsDto {
  claimId?: string;
  episodesOfCare?: string[];
  includeInactive?: boolean;
}

export enum ClaimStatuses {
  Accepted = "ACCEPTED",
  Accredited = "ACCREDITED EMPLOYER",
  Declined = "DECLINE",
  Error = "ERROR",
  Held = "HELD",
  Incomplete = "INCOMPLETE",
  NotAvailable = "NOTAVAILABLE",
  NotVerified = "NOT VERIFIED",
  Pending = "PENDING",
  Private = "PRIVATE",
  Queued = "QUEUED",
  Ready = "READY",
  Discharged = "DISCHARGED",
  GetStatusError = "GETSTATUSERROR"
}

export enum ClaimStatusText {
  NotAvailable = "Not avail.",
  Incomplete = "Incomplete"
}

export enum ScheduleProcessingStatuses {
  Queued = "QUEUED",
  Pending = "PENDING",
  Authorised = "AUTHORISED",
  AwaitingAuthorisation = "AWAITING AUTHORISATION",
  Invalid = "INVALID",
  CompleteAndAwaitingValidation = "COMPLETEANDAWAITINGVALIDATION",
  Paid = "PAID",
  Cancelled = "CANCELLED",
  PartiallyPaid = "PARTIALLY PAID",
  InException = "IN EXCEPTION",
  SentToAR = "SENT TO AR",
  Incomplete = "INCOMPLETE",
  Error = "ERROR"
}

export enum ScheduleItemPaymentStatuses {
  Queued = "QUEUED",
  Pending = "PENDING",
  Authorised = "AUTHORISED",
  AwaitingAuthorisation = "AWAITINGAUTHORISATION",
  SelectedForPayAdvice = "SELECTEDFORPAYADVICE",
  PaymentCancelled = "PAYMENTCANCELLED",
  Paid = "PAID",
  RemitAdvised = "REMITADVISED",
  SentToAR = "SENTTOAR",
  Error = "ERROR"
}

export enum InvoiceStatusText {
  Unsubmitted = "UNSUBMITTED"
}

export enum MultiProviderErrorCode {
  MultiProviderError = "MultiProviderError"
}

export interface PracticeContractType extends RefDataDto {
  providerTypeCode: string;
}

export interface RefAccTerminology extends RefDataDto {
  aCCAcceptable: boolean;
  aCC32Acceptable: boolean;
  readCode?: string;
  readTerm?: string;
}

export interface RefClaimStatus extends RefDataDto {
  claimUpdateAllowed: boolean;
}

export interface RefClaimAdjustmentStatus extends RefDataDto {
  allowUpdate: boolean;
  allowLodge: boolean;
  allowResponse: boolean;
}

export interface RefProviderContractTypeDto extends RefDataDto {
  contractType?: string;
  providerTypeCode?: string;
}

export interface RefClaimAdjustmentDocumentType extends RefDataDto {
  clinicalDocTypeCode: CorrespondenceType;
}

export interface AccBenefitCountDto {
  contractClass: string;
  count: number;
}

export interface GetAccBenefitCountsDto {
  contractClasses?: string[];
  effectiveDate: string;
}

export interface BillableOrganisationDto {
  contactId: string;
}

export interface AccTerminologyDto {
  code: string;
  text: string;
  diagnosisKey: string;
  aCCAcceptable?: boolean;
  aCC32Acceptable?: boolean;
  readCode?: string;
  codeSystem?: string;
  version?: string;
}

export interface GetAccTerminologiesArgsDto extends PagingOptions {
  search?: string;
}

export interface PurchaseOrderDto {
  purchaseOrderNumber?: string;
  purchaseOrderNotes?: string;
  claimAdjustmentId?: string;
  changeLog?: ChangeLogDto;
  responseDate?: string;
  claimId: string;
  eTag: string;
  id: string;
}

export interface AddPurchaseOrderDto
  extends Omit<PurchaseOrderDto, "id" | "eTag" | "changeLog"> {
  purchaseOrderNumber?: string;
  purchaseOrderNotes?: string;
  claimAdjustmentId?: string;
  changeLog?: ChangeLogDto;
  responseDate?: string;
  claimId: string;
}

export interface AccOccupation extends RefDataDto {
  anzscoCode?: string; // Single code or comma delimited codes (for many to one mapping)
}

export interface EmailAcc45SummaryArgs {
  claimId: string;
  documentId: string;
  emailAddress: string;
}

export enum ACCSex {
  Male = "M",
  Female = "F",
  Other = "O",
  Unknown = "U"
}

export interface DocumentStagingInfo {
  url: string;
  stagingId: string;
  fileStagingId: string;
}

export type ClaimPreviewDocumentRequired = {
  show: boolean;
  patientId: string;
  documentId: string;
};

export type ClaimPreviewDocumentData =
  | (ClaimPreviewDocumentRequired & { url: string; templateUriContent?: never })
  | (ClaimPreviewDocumentRequired & {
      url?: never;
      templateUriContent: string;
    });

export interface ProviderTypeDto extends RefDataDto {
  allowAcc45: boolean;
  referralOutOnly: boolean;
  userTypeCode: string;
}

export enum ClaimAdjustmentReasons {
  hasDiagnosisChange = "hasDiagnosisChange",
  hasDiagnosisAddition = "hasDiagnosisAddition",
  hasTreatmentDetails = "hasTreatmentDetails",
  hasSideChange = "hasSideChange"
}

export enum ClaimAdjustmentDocumentTypes {
  ExtensionOfTreatment = "MMEX32PHY",
  NoticeOfDischarge = "MMEXDISOP1",
  OperationNotes = "MMEXDISOP2",
  SurgeryRequestsAndDocumentationTreatment = "MMEXEARTP",
  RadiologyReports = "MMEXESHTI",
  HighTechImaging = "MMEXHSHTI",
  GuidedInjectionRequests = "MMEXPRAPR",
  SpecialistNotes = "MMEXSPECR"
}

export enum DiagnosisSides {
  Left = "left",
  Right = "right",
  Bilateral = "bilateral",
  NotApplicable = "notApplicable"
}

export enum EmploymentStatuses {
  Other = "5",
  IAmAnEmployee = "1",
  IAmSelfEmployed = "3",
  IOwnOrPartOwnMyPlaceOfWork = "11"
}

export enum DiagnosisStatuses {
  Investigating = "Investigating",
  Provisional = "Provisional",
  Approved = "Approved",
  Declined = "Declined"
}

export const DOCUMENT_FILE_SIZE_LIMIT = 20971520;

export interface GetSchedulesArgsDto {
  claimId?: string;
  invoiceIds?: string[];
  invoiceNumbers?: string[];
  processingStatuses?: string[];
}

export interface ScheduleDto {
  id: string;
  eTag: string;
  invoiceId: string;
  processingStatus: ScheduleProcessingStatuses;
  errorMessages?: ErrorMessageDto[];
  items?: ScheduleItemDto[];
}

export interface ScheduleItemDto {
  id: string;
  claimId: string;
  claimNumber?: string;
}
